/**
 * @generated SignedSource<<3cbef012cdad543c8ec5ce37e4e4df49>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type enum_color_scheme_enum = "DARK" | "LIGHT" | "%future added value";
export type colorSchemeContextMutation$variables = {
  colorScheme: enum_color_scheme_enum;
  id: string;
};
export type colorSchemeContextMutation$data = {
  readonly update_users_by_pk: {
    readonly colorSchemePreference: enum_color_scheme_enum | null | undefined;
  } | null | undefined;
};
export type colorSchemeContextMutation = {
  response: colorSchemeContextMutation$data;
  variables: colorSchemeContextMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "colorScheme"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "colorSchemePreference",
        "variableName": "colorScheme"
      }
    ],
    "kind": "ObjectValue",
    "name": "_set"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "pk_columns"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "colorSchemePreference",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "colorSchemeContextMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "users",
        "kind": "LinkedField",
        "name": "update_users_by_pk",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "colorSchemeContextMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "users",
        "kind": "LinkedField",
        "name": "update_users_by_pk",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7242ffaeee9483580605848e15341d52",
    "id": null,
    "metadata": {},
    "name": "colorSchemeContextMutation",
    "operationKind": "mutation",
    "text": "mutation colorSchemeContextMutation(\n  $id: String!\n  $colorScheme: enum_color_scheme_enum!\n) {\n  update_users_by_pk(pk_columns: {id: $id}, _set: {colorSchemePreference: $colorScheme}) {\n    colorSchemePreference\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "12a6d92b26af1505f2c475e59e85e864";

export default node;
