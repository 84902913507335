import { useUser } from '@auth0/nextjs-auth0/client'
import React, {
  createContext,
  SyntheticEvent,
  useContext,
  useEffect
} from 'react'
import { usePathname } from 'next/navigation'
import { EventType, Event } from '../../lib/eventClient/eventClient'

interface AnalyticsContext {
  pageView?: (pathname: string) => void
  clickEvent?: (pathname: SyntheticEvent) => void
}

const EventClientContext = createContext<AnalyticsContext>({
  pageView: undefined,
  clickEvent: undefined
})

export const useEventClient = () => useContext(EventClientContext)

interface AnalyticsContextProviderProps {
  children: React.ReactNode
}

export const AnalyticsProvider = ({
  children
}: AnalyticsContextProviderProps) => {
  const workerRef = React.useRef<Worker>()
  const { user, isLoading } = useUser()
  const pathname = usePathname()
  const [eventCache, setEventCacheItem] = React.useState<Array<Event>>([])

  React.useEffect(() => {
    if (!window.Worker) return
    workerRef.current = new Worker(
      new URL('../../workers/analyticsWorker/analyticsWorker', import.meta.url)
    )

    return () => {
      workerRef.current?.terminate()
    }
  }, [user?.sub])

  const pageView = React.useCallback(
    (pathname: string) => {
      if (!user?.sub || isLoading || !workerRef.current) return

      workerRef.current.postMessage({
        userId: user?.sub,
        eventType: EventType.PAGE_VIEW,
        event: {
          pageUrl: pathname
        }
      })
    },
    [isLoading, user?.sub]
  )

  const clickEvent = React.useCallback(
    (event: SyntheticEvent) => {
      const target = event.currentTarget
      const elementText = target.textContent || ''

      if (!user?.sub || isLoading || !workerRef.current || !pathname) {
        // if we don't have a user, lets cache these clicks and send them when we do
        setEventCacheItem([
          ...eventCache,
          {
            eventType: EventType.CLICK,
            event: {
              elementText
            }
          }
        ])

        return
      }

      workerRef.current.postMessage({
        userId: user?.sub,
        eventType: EventType.CLICK,
        event: {
          pageUrl: pathname,
          elementText
        }
      })
    },
    [eventCache, isLoading, pathname, user?.sub]
  )

  // If there is anything in the cache, send it to the worker when the data is available
  React.useEffect(() => {
    if (user?.sub && pathname && eventCache.length > 0 && workerRef.current) {
      eventCache.forEach((event) => {
        workerRef.current?.postMessage({
          userId: user?.sub,
          eventType: event.eventType,
          event: {
            ...event.event,
            pageUrl: pathname
          }
        })
      })
      setEventCacheItem([])
    }
  }, [eventCache, pathname, user?.sub])

  useEffect(() => {
    if (!pathname) return
    pageView(pathname)
  }, [pageView, pathname])

  return (
    <EventClientContext.Provider value={{ pageView, clickEvent }}>
      {children}
    </EventClientContext.Provider>
  )
}
