export const getBeginningOfClassString = (classString: string): string =>
  (classString.match(/^(.+-).+/) || '')[1]

/**
 * Checks if a tailwind prefix already exists in a class string and if so
 * replaces it with the new class of the same type
 * @param className1
 * @param className2
 */
export const classAlreadyExists = (
  className1: string,
  className2: string
): boolean =>
  getBeginningOfClassString(className1) ===
  getBeginningOfClassString(className2)

const removeDuplicateClasses = (
  classString: string,
  classAddition?: string
): string => {
  if (!classAddition) return classString
  const classStringClasses = classString.split(' ')
  const classAdditionClasses = classAddition.split(' ')

  return classStringClasses.reduce((acc: string, value: string): string => {
    return classAdditionClasses.some((className) =>
      classAlreadyExists(value, className)
    )
      ? acc
      : `${acc} ${value}`
  }, classAddition)
}

export default removeDuplicateClasses
