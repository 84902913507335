import { HTMLMotionProps, motion } from 'framer-motion'
import classNames from 'classnames'
import removeDuplicateClasses from '../../../utils/removeDuplicateClasses/removeDuplicateClasses'
import { useEventClient } from '../../../context/analyticsContext/analyticsContext'
import { Oval } from 'react-loading-icons'

export enum ButtonSize {
  DEFAULT = 'default',
  SMALL = 'small',
  LARGE = 'large'
}

export enum ButtonVariant {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  ALERT = 'alert',
  REPORT = 'report',
  CLEAR = 'clear'
}

export interface ButtonProps extends HTMLMotionProps<'button'> {
  size?: ButtonSize
  styleVariant?: ButtonVariant
  loading?: boolean
  disabled?: boolean
  navigationButton?: boolean
  navigationIsOpen?: boolean
}

export default function Button({
  className = '',
  size = ButtonSize.DEFAULT,
  styleVariant = ButtonVariant.DEFAULT,
  navigationButton = false,
  navigationIsOpen = false,
  loading,
  ...restProps
}: ButtonProps) {
  const { clickEvent } = useEventClient()
  let props = restProps

  if (restProps.onClick) {
    props = {
      ...restProps,
      onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        clickEvent && clickEvent(event)
        restProps.onClick && restProps.onClick(event)
      }
    }
  } else {
    props = {
      ...restProps,
      onClick: clickEvent
    }
  }

  const content = loading ? (
    <div className="relative">
      <div
        role="status"
        className="absolute inset-0 flex items-center justify-center"
      >
        <Oval stroke="#fff" strokeWidth={8} className="h-4 w-4" />
        <span className="sr-only">Loading...</span>
      </div>
      <span className="opacity-0">{props.children as React.ReactNode}</span>
    </div>
  ) : (
    props.children
  )

  return (
    <motion.button
      className={classNames(
        {
          'bg-lightBlue text-white': styleVariant === ButtonVariant.DEFAULT
        },
        { 'bg-errorRed text-white': styleVariant === ButtonVariant.ALERT },
        {
          'bg-transparent text-errorRed border border-errorRed':
            styleVariant === ButtonVariant.REPORT
        },
        {
          'bg-darkBlue text-white': styleVariant === ButtonVariant.PRIMARY
        },
        {
          'bg-transparent text-black dark:text-white':
            styleVariant === ButtonVariant.CLEAR
        },
        {
          'bg-baseGray dark:bg-overlayBlue text-white':
            styleVariant === ButtonVariant.SECONDARY
        },
        { 'text-sm': size === ButtonSize.DEFAULT },
        { 'text-base': size === ButtonSize.LARGE },
        { 'text-xs': size === ButtonSize.SMALL },
        { 'rounded-lg round-lg': navigationButton && !navigationIsOpen },
        { 'rounded-xl round-xl': !navigationButton },
        { 'rounded-xl round-xl': navigationButton && navigationIsOpen },
        removeDuplicateClasses(
          'px-5 py-2 font-semibold disabled:opacity-50 disabled:cursor-not-allowed',
          className
        )
      )}
      role="button"
      {...props}
    >
      {content}
    </motion.button>
  )
}
