import React from 'react'
import { graphql, useFragment, useMutation } from 'react-relay/hooks'
import {
  colorSchemeContextMutation,
  enum_color_scheme_enum
} from './__generated__/colorSchemeContextMutation.graphql'
import { useUser } from '@auth0/nextjs-auth0/client'
import toast from 'react-hot-toast'
import { colorSchemeContextFragment$key } from './__generated__/colorSchemeContextFragment.graphql'

interface ColorSchemeContextProps {
  colorScheme: enum_color_scheme_enum
  handleDarkModeChange: () => void
}

interface ColorSchemeContextProviderProps {
  divRef: React.RefObject<HTMLDivElement>
  rootRef: React.RefObject<HTMLElement>
  data: colorSchemeContextFragment$key
}

const ColorSchemeContextMutation = graphql`
  mutation colorSchemeContextMutation(
    $id: String!
    $colorScheme: enum_color_scheme_enum!
  ) {
    update_users_by_pk(
      pk_columns: { id: $id }
      _set: { colorSchemePreference: $colorScheme }
    ) {
      colorSchemePreference
    }
  }
`

const ColorSchemeContextFragment = graphql`
  fragment colorSchemeContextFragment on usersConnection {
    edges {
      node {
        colorSchemePreference
      }
    }
  }
`

export const ColorSchemeContext = React.createContext<ColorSchemeContextProps>({
  colorScheme: 'DARK',
  handleDarkModeChange: () => undefined
})

export const useColorSchemeContext = () => React.useContext(ColorSchemeContext)

export const switchColorScheme = (colorScheme: enum_color_scheme_enum) =>
  colorScheme === 'DARK' ? 'LIGHT' : 'DARK'

export default function ColorSchemeContextProvider({
  children,
  divRef,
  rootRef,
  data
}: React.PropsWithChildren<ColorSchemeContextProviderProps>) {
  const { edges } = useFragment(ColorSchemeContextFragment, data)
  const colorSchemePreference = edges[0]?.node?.colorSchemePreference
  const [colorScheme, setColorScheme] = React.useState<enum_color_scheme_enum>(
    colorSchemePreference || 'DARK'
  )
  const { user } = useUser()

  const [commitMutation] = useMutation<colorSchemeContextMutation>(
    ColorSchemeContextMutation
  )

  React.useEffect(() => {
    if (colorSchemePreference) {
      setColorScheme(colorSchemePreference)
    }
  }, [colorSchemePreference, setColorScheme])

  const handleDarkModeChange = React.useCallback(async () => {
    const toggleDarkMode = (switchedToColor: string) => {
      setColorScheme(switchColorScheme)

      if (switchedToColor === 'DARK') {
        divRef.current?.classList.add('dark')
        rootRef.current?.classList.add('dark')
      } else {
        divRef.current?.classList.remove('dark')
        rootRef.current?.classList.remove('dark')
      }
    }

    const id = user?.sub

    if (rootRef.current === null || !id) return
    const switchedToColor = switchColorScheme(colorScheme)

    let cookieSettingFailed = false
    await fetch('/api/cookies', {
      method: 'POST',
      body: JSON.stringify(switchedToColor)
    }).catch((err) => {
      console.info('Cookie setting failed', err)
      cookieSettingFailed = true
    })
    toggleDarkMode(switchedToColor)

    toast.promise(
      new Promise((resolve, reject) => {
        commitMutation({
          variables: {
            id,
            colorScheme: switchedToColor
          },
          onCompleted: (response) => {
            resolve(response)
          },
          onError: (error) => {
            console.warn('ColorSchemeContextMutation failed:', error)
            // when it fails, revert the change and show a toast
            toggleDarkMode(switchedToColor)

            if (!cookieSettingFailed) {
              fetch('/api/cookies', {
                method: 'POST',
                body: JSON.stringify(switchedToColor)
              })
                .catch((err) => {
                  console.info('Cookie setting failed', err)
                  cookieSettingFailed = true
                })
                .then(() => {
                  reject(error)
                })
            } else {
              reject(error)
            }
          }
        })
      }),
      {
        loading: 'Saving color scheme preference...',
        success: 'Preference updated',
        error: 'Error updating color scheme preference. Please try again.'
      }
    )
  }, [commitMutation, divRef, user?.sub, rootRef, colorScheme])

  return (
    <ColorSchemeContext.Provider
      value={{
        colorScheme,
        handleDarkModeChange
      }}
    >
      {children}
    </ColorSchemeContext.Provider>
  )
}
